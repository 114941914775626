<template>
    <form @submit.prevent="handleSubmit" class="h-full">
        <PageWithButtonBelow :title="t('expert_focus')">
            <template v-slot:above-title>
                <TinyNavigation :prev="{}" />
            </template>

            <template v-slot:default>
                <TextAreaInput v-model:value="form.extra_attention" v-model:errors="all_errors" rows="5" :label="t('extra_attention_label', {coach_name})" :placeholder="t('extra_attention_placeholder')" />
            </template>

            <template v-slot:button>
                <span class="w-full rounded-md shadow-sm">
                    <button type="submit" class="button w-full">
                        {{ t('send') }}
                    </button>
                </span>
            </template>
        </PageWithButtonBelow>
    </form>
</template>

<script>
import PageWithButtonBelow from "@/wrappers/PageWithButtonBelow";
import TinyNavigation from "@/components/TinyNavigation";
import TextAreaInput from "@/components/forms/TextAreaInput";
import { showExpert } from "@/helpers/api/ExpertHelper";
import messages from "@/helpers/storage/MessageHelper";
import { startSpinner, stopSpinner } from "@/helpers/storage/loadingSpinnerHelper";
import useI18n from "@/modules/globalI18n";
import stripePromise from "@/stripe"
import { createStripeSession } from "@/helpers/api/StripeHelper"

export default {
    name: "ReviewCreateSubmit",
    setup() {
        return useI18n({
            viewPrefix: "ReviewCreateSubmit"
        });
    },
    components: { PageWithButtonBelow, TinyNavigation, TextAreaInput },
    created(){
        this.loadExpert();
        this.initStripe();
    },
    data(){
        return {
            coach_name: 'de expert',
            form: {
                extra_attention: "",
            },
            all_errors: [],
            stripe: undefined,
        }
    },
    methods: {
        async loadExpert() {
            if(!this.$route.query.coach_id){
                return 
            }
            this.coach_name = (await showExpert(this.$route.query.coach_id)).name
        },
        async initStripe() {
            this.stripe = await stripePromise
        },
        async initStripePaymentSession() {
            const query = this.$route.query;
            return createStripeSession(query.match_id, query.product_id, query.coach_id, this.form)
                .catch(e => {
                    if (e?.response?.data?.errors) {
                        const error_values = Object.values(e.response.data.errors);
                        this.all_errors = error_values.flat(1);
                    } else {
                        throw e
                    }
                });
        },
        async handleSubmit(){
            startSpinner()

            const session = await this.initStripePaymentSession();
            const options = { 
                sessionId: session.id,
            }

            this.stripe.redirectToCheckout(options)
                .catch(err =>{
                    messages.error(this.t('messages.something_went_wrong', {error: err.message}));

                    const error_values = Object.values(err.errors);
                    this.all_errors = error_values.flat(1);

                    this.errors = err.errors ?? [];
                })
                .finally(() => stopSpinner());
        }
    }
}
</script>
