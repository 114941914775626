const EXPERT_ROLE_PLAYER = "Player";
const EXPERT_ROLE_FIGHTER = "Fighter";
const EXPERT_ROLE_COACH = "Trainer";
const EXPERT_ROLE_SCOUT = "Scout";
const EXPERT_ROLE_REFEREE = "Referee";

const constants = {
    EXPERT_ROLE_PLAYER,
    EXPERT_ROLE_FIGHTER,
    EXPERT_ROLE_COACH,
    EXPERT_ROLE_SCOUT,
    EXPERT_ROLE_REFEREE
}

function createExpert(expert) {

    // for testing
    // const roles = ['player', 'trainer', 'kaas', null]
    // expert.role = roles[Math.floor(Math.random() * roles.length)];

    const functions =  {
        isRole: (role) => {
            if (!expert.role) {
                return null;
            }
            return expert.role.name === role;
        },
        getRole: () => {
            return expert.role?.name ?? null;
        },
        getRoleId: () => {
            return expert.role?.id ?? null;
        }
    }
    

    return {...expert, ...functions};
}

function expertRolePlural(role) {
    switch (role.toLowerCase()) {
        case 'player':
            return 'players';

        case 'coach':
            return 'coaches';

        case 'scout':
            return 'scouts';

        case 'fighter':
            return 'fighters';

        case 'trainer':
            return 'trainers';

        case 'promoter':
            return 'promoters';

        case 'referee':
          return 'referee';

        default:
            return role
    }
}

export { createExpert, constants, expertRolePlural }    
    

