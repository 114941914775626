import axios from 'axios';
import { createExpert } from "@/models/ExpertFactory";

const index_coach_path = "v1/trainee/coach"; // get
const show_coach_path = "v1/trainee/coach/"; // + id // get


function indexExperts()
{
    return new Promise((resolve, reject) => {
        axios.get(index_coach_path)
            .then((response)=>{
                resolve(response.data.coaches.map(coach => createExpert(coach)));
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

function showExpert(id)
{
    const url = show_coach_path + id;
    return new Promise((resolve, reject) => {
        axios.get(url)
            .then((response)=>{
                resolve(response.data.coach);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

export { indexExperts, showExpert };